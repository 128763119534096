import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ImageFromStarterPleaseStartHere from "../components/imageFromStarterPleaseStartHere"

export default () => (
  <Layout>
    <ImageFromStarterPleaseStartHere/>
    <SEO title="About" />
    <h1>About</h1>
    <p>GO Results is brought to you by NS Solutions and made in Greater Manchester.</p>
    
  </Layout>
)
